

import orderList from '../../margemonitor/components/orders/dimensions-WTR';
import ritList from '../../margemonitor/components/ritten/dimensions-WTR';
import orderTripList from '../../margemonitor/components/trajecten/dimensions-WTR';

const options = {
  dimensions: {
    order: orderList,
    rit: ritList,
    traject: orderTripList,
  },
  config: {
    host: 'wessels-rijssen.eu.qlikcloud.com', 
    secure: true,
    port: 443,
    prefix: '',
    webIntegrationId: '3hCiAqxqOqYqUe0ZvDNuO7LkpBy1zAXX',  
    //appId: '7def1c3f-9e6d-481a-b50f-81b5b96af1a0'
    appId: '89d3f9a7-6647-4084-896c-e2a53c00a2f9'
  },
  connections: {
    qlikCloud: true, 
    vizApi: true,
    engineApi: true,
    useUniqueSessionID: 'MargeMonitor'
  }, 
  filters: {
    calendar: {
      replace: false,
      filters: [
        {
          type: 'QdtFilter',
          props: {
            cols: ['$(vD_Year)'],
            placeholder: 'Jaar',
            expanded: true, 
            expandedHorizontal: true,
            expandedHorizontalSense: true,
            qSortByAscii: 1,
            autoSortByState: 0
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['$(vD_Quarter)'],
            placeholder: 'Kwartaal',
            expandedHorizontal: true,
            expandedHorizontalSense: true,
            qSortByAscii: 1,
            autoSortByState: 0,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['$(vD_Month)'],
            placeholder: 'Maand',
            expandedHorizontal: true,
            expanded: true, 
            expandedHorizontalSense: true,
            qSortByAscii: 1,
            autoSortByState: 0,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Weekday)]'],
            placeholder: 'Dag',
            expandedHorizontal: true,
            expanded: true, 
            expandedHorizontalSense: true,
            qSortByAscii: 1,
            autoSortByState: 0,
          }
        },   
        {
          type: 'QdtFilter',
          props: {
            cols: ['$(vD_Week)'],
            placeholder: 'Week',
            autoSortByState: 0,
            qSortByAscii: 1,
            autoSortByState: 0,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['$(vD_Date)'],
            placeholder: 'Datum',
          },
        },
               
      ],
    },
    location: {
      replace: false,
      filters: [
        {
          type: 'title',
          title: 'Order Laadlocatie'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.ID)]'],
            placeholder: 'ID',
            qSortByAscii: 1,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.Adress)]'],
            placeholder: 'Adres',
            qSortByAscii: 1,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.ZIPcode)]'],
            placeholder: 'Postcode',
            qSortByAscii: 1,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.City)]'],
            placeholder: 'City',
            qSortByAscii: 1,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.District)]'],
            placeholder: 'Gemeente',
            qSortByAscii: 1,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.Province)]'],
            placeholder: 'Provincie',
            qSortByAscii: 1,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.Country)]'],
            placeholder: 'Land',
            qSortByAscii: 1,
            autoSortByState: 1
          },
        },
        {
          type: 'title',
          title: 'Order Loslocatie'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.ID)]'],
            placeholder: 'ID',
            qSortByAscii: 1,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.Adress)]'],
            placeholder: 'Adres',
            qSortByAscii: 1,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.ZIPcode)]'],
            placeholder: 'Postcode',
            qSortByAscii: 1,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.City)]'],
            placeholder: 'City',
            qSortByAscii: 1,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.District)]'],
            placeholder: 'Gemeente',
            qSortByAscii: 1,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.Province)]'],
            placeholder: 'Provincie',
            qSortByAscii: 1,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.Country)]'],
            placeholder: 'Land',
            qSortByAscii: 1,
            autoSortByState: 1
          },
        },
      ],
    },
    order: {
      replace: false,
      filters: [
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.OrderNumberDisplay)]'],
            placeholder: 'Ordernummer',
            showStateInDropdown: true,
            qSortByAscii: 1,
            autoSortByState: 1
          },
        },
        {
          type: 'title',
          title: 'Klant'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['$(vD_Order.Debtor.Name)'],
            placeholder: 'Klantnaam',
            showStateInDropdown: true,
            qSortByAscii: 1,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Debtor.Number)]'],
            placeholder: 'DebiteurNummer',
            showStateInDropdown: true,
            qSortByAscii: 1,
            autoSortByState: 1
          },
        },
        { 
          type: 'title',
          title: 'Afdeling'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Kind)]'],
            placeholder: 'Ordersoort',
            showStateInDropdown: true,
            qSortByAscii: 1,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.PlanGroup)]'],
            placeholder: 'Plangroep',
            showStateInDropdown: true,
            qSortByAscii: 1,
            autoSortByState: 1
          },
        },
        { 
          type: 'title',
          title: 'Goederen'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Product)]'],
            placeholder: 'Product',
            showStateInDropdown: true,
            qSortByAscii: 1,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_ProductDescription)]'],
            placeholder: 'Product Omschrijving',
            showStateInDropdown: true,
            qSortByAscii: 1,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Weight)]'],
            placeholder: 'KG',
            showStateInDropdown: true,
            qSortByAscii: 1,
            autoSortByState: 1
          },
        },
      ],
    },
    trip: {
      replace: false,
      filters: [
        { 
          type: 'title',
          title: "Rit" 
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.TripNumber)]'],
            placeholder: 'Rit nummer',
            showStateInDropdown: true,
            qSortByAscii: 1,
            autoSortByState: 1
          },
        },
        { 
          type: 'title',
          title: "Auto" 
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Vehicle.Number)]'],
            placeholder: 'Auto nummer',
            showStateInDropdown: true,
            qSortByAscii: 1,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Vehicle.Name)]'],
            placeholder: 'Auto naam',
            showStateInDropdown: true,
            qSortByAscii: 1,
            autoSortByState: 1
          },
        },
        { 
          type: 'title',
          title: "Chauffeur" 
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Driver.Number)]'],
            placeholder: 'Chauffeur nummer',
            showStateInDropdown: true,
            qSortByAscii: 1,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Driver.Name)]'],
            placeholder: 'Chauffeur naam',
            showStateInDropdown: true,
            qSortByAscii: 1,
            autoSortByState: 1
          },
        },
        { 
          type: 'title',
          title: "Crediteur" 
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.Outsourced)]'],
            placeholder: 'Uitbesteed',
            showStateInDropdown: true,
            qSortByAscii: 1,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.Creditor.Kind.Description)]'],
            placeholder: 'Chartersoort',
            showStateInDropdown: true,
            qSortByAscii: 1,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.Creditor.Number)]'],
            placeholder: 'Crediteur nummer',
            showStateInDropdown: true,
            qSortByAscii: 1,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.Creditor.Name)]'],
            placeholder: 'Crediteur naam',
            showStateInDropdown: true,
            qSortByAscii: 1,
            autoSortByState: 1
          },
        },
      ],
    },
  },
  menu: {
    disable: [],
    menu: [
      {
        title: true,
        name: '',
        wrapper: {
          element: '',
          attributes: {},
        },
      }
    ]
  },
  routes: [
  ],
  fieldSettings:{
    period: 'vD_Month'
  },
  selectionBar: 'Nebula',
  rollingPeriod: true,
  language: 'nl',
  defaultSelections: [
    {
      field: 'Jaar',
      expression: '=year(today())'
    }
  ]

};

export default options;
