const options = {
  config: {
    host: 'qs01.brinkstransport.local',
    secure: true,
    port: 443,
    prefix: '',
    appId: 'd347d54c-89b9-4e28-93fe-03fab3a4a303',
  },
  connections: {
    vizApi: true,
    engineApi: true,
    useUniqueSessionID: 'MargeMonitor'
  },
  selectionBar: 'Capability',
  rollingPeriod: false
};

export default options;
