const dimensions = {
    vehicle: {
        number: {
            "qLibraryId": "",
            "qDef": {
              "qGrouping": "N",
              "qFieldDefs": [
                "[$(vD_Vehicle.Number)]"
              ],
              "qFieldLabels": [
                ""
              ],
              "qSortCriterias": [
                {
                  "qSortByState": 0,
                  "qSortByFrequency": 0,
                  "qSortByNumeric": 1,
                  "qSortByAscii": 1,
                  "qSortByLoadOrder": 1,
                  "qSortByExpression": 0,
                  "qExpression": {
                    "qv": ""
                  },
                  "qSortByGreyness": 0
                }
              ],
              "qNumberPresentations": [],
              "qReverseSort": false,
              "qActiveField": 0,
              "qLabelExpression": "='$(vD_Vehicle.Number)'",
              "autoSort": true,
              "othersLabel": "Overige",
              "textAlign": {
                "auto": true,
                "align": "left"
              },
              "representation": {
                "type": "text",
                "urlLabel": ""
              }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
              "qOtherMode": "OTHER_OFF",
              "qOtherCounted": {
                "qv": "10"
              },
              "qOtherLimit": {
                "qv": "0"
              },
              "qOtherLimitMode": "OTHER_GE_LIMIT",
              "qSuppressOther": false,
              "qForceBadValueKeeping": true,
              "qApplyEvenWhenPossiblyWrongResult": true,
              "qGlobalOtherGrouping": false,
              "qOtherCollapseInnerDimensions": false,
              "qOtherSortMode": "OTHER_SORT_DESCENDING",
              "qTotalMode": "TOTAL_OFF",
              "qReferencedExpression": {
                "qv": ""
              }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
              "qv": "Overige"
            },
            "qTotalLabel": {
              "qv": ""
            },
            "qCalcCond": {
              "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        name: {
            "qLibraryId": "",
            "qDef": {
              "qGrouping": "N",
              "qFieldDefs": [
                "[$(vD_Vehicle.Name)]"
              ],
              "qFieldLabels": [
                ""
              ],
              "qSortCriterias": [
                {
                  "qSortByState": 0,
                  "qSortByFrequency": 0,
                  "qSortByNumeric": 1,
                  "qSortByAscii": 1,
                  "qSortByLoadOrder": 1,
                  "qSortByExpression": 0,
                  "qExpression": {
                    "qv": ""
                  },
                  "qSortByGreyness": 0
                }
              ],
              "qNumberPresentations": [],
              "qReverseSort": false,
              "qActiveField": 0,
              "qLabelExpression": "='$(vD_Vehicle.Name)'",
              "autoSort": true,
              "othersLabel": "Overige",
              "textAlign": {
                "auto": true,
                "align": "left"
              },
              "representation": {
                "type": "text",
                "urlLabel": ""
              }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
              "qOtherMode": "OTHER_OFF",
              "qOtherCounted": {
                "qv": "10"
              },
              "qOtherLimit": {
                "qv": "0"
              },
              "qOtherLimitMode": "OTHER_GE_LIMIT",
              "qSuppressOther": false,
              "qForceBadValueKeeping": true,
              "qApplyEvenWhenPossiblyWrongResult": true,
              "qGlobalOtherGrouping": false,
              "qOtherCollapseInnerDimensions": false,
              "qOtherSortMode": "OTHER_SORT_DESCENDING",
              "qTotalMode": "TOTAL_OFF",
              "qReferencedExpression": {
                "qv": ""
              }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
              "qv": "Overige"
            },
            "qTotalLabel": {
              "qv": ""
            },
            "qCalcCond": {
              "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        license:   {
            "qLibraryId": "",
            "qDef": {
              "qGrouping": "N",
              "qFieldDefs": [
                "[$(vD_Vehicle.License)]"
              ],
              "qFieldLabels": [
                ""
              ],
              "qSortCriterias": [
                {
                  "qSortByState": 0,
                  "qSortByFrequency": 0,
                  "qSortByNumeric": 1,
                  "qSortByAscii": 1,
                  "qSortByLoadOrder": 1,
                  "qSortByExpression": 0,
                  "qExpression": {
                    "qv": ""
                  },
                  "qSortByGreyness": 0
                }
              ],
              "qNumberPresentations": [],
              "qReverseSort": false,
              "qActiveField": 0,
              "qLabelExpression": "='$(vD_Vehicle.License)'",
              "autoSort": true,
              "othersLabel": "Overige",
              "textAlign": {
                "auto": true,
                "align": "left"
              },
              "representation": {
                "type": "text",
                "urlLabel": ""
              }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
              "qOtherMode": "OTHER_OFF",
              "qOtherCounted": {
                "qv": "10"
              },
              "qOtherLimit": {
                "qv": "0"
              },
              "qOtherLimitMode": "OTHER_GE_LIMIT",
              "qSuppressOther": false,
              "qForceBadValueKeeping": true,
              "qApplyEvenWhenPossiblyWrongResult": true,
              "qGlobalOtherGrouping": false,
              "qOtherCollapseInnerDimensions": false,
              "qOtherSortMode": "OTHER_SORT_DESCENDING",
              "qTotalMode": "TOTAL_OFF",
              "qReferencedExpression": {
                "qv": ""
              }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
              "qv": "Overige"
            },
            "qTotalLabel": {
              "qv": ""
            },
            "qCalcCond": {
              "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        type:   {
            "qLibraryId": "",
            "qDef": {
              "qGrouping": "N",
              "qFieldDefs": [
                "[$(vD_Trip.Vehicle.Type)]"
              ],
              "qFieldLabels": [
                ""
              ],
              "qSortCriterias": [
                {
                  "qSortByState": 0,
                  "qSortByFrequency": 0,
                  "qSortByNumeric": 1,
                  "qSortByAscii": 1,
                  "qSortByLoadOrder": 1,
                  "qSortByExpression": 0,
                  "qExpression": {
                    "qv": ""
                  },
                  "qSortByGreyness": 0
                }
              ],
              "qNumberPresentations": [],
              "qReverseSort": false,
              "qActiveField": 0,
              "qLabelExpression": "='$(vD_Trip.Vehicle.Type)'",
              "autoSort": true,
              "othersLabel": "Overige",
              "textAlign": {
                "auto": true,
                "align": "left"
              },
              "representation": {
                "type": "text",
                "urlLabel": ""
              }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
              "qOtherMode": "OTHER_OFF",
              "qOtherCounted": {
                "qv": "10"
              },
              "qOtherLimit": {
                "qv": "0"
              },
              "qOtherLimitMode": "OTHER_GE_LIMIT",
              "qSuppressOther": false,
              "qForceBadValueKeeping": true,
              "qApplyEvenWhenPossiblyWrongResult": true,
              "qGlobalOtherGrouping": false,
              "qOtherCollapseInnerDimensions": false,
              "qOtherSortMode": "OTHER_SORT_DESCENDING",
              "qTotalMode": "TOTAL_OFF",
              "qReferencedExpression": {
                "qv": ""
              }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
              "qv": "Overige"
            },
            "qTotalLabel": {
              "qv": ""
            },
            "qCalcCond": {
              "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          }
    },
    driver: {
        name: {
            "qLibraryId": "",
            "qDef": {
              "qGrouping": "N",
              "qFieldDefs": [
                "[$(vD_Driver.Name)]"
              ],
              "qFieldLabels": [
                ""
              ],
              "qSortCriterias": [
                {
                  "qSortByState": 0,
                  "qSortByFrequency": 0,
                  "qSortByNumeric": 1,
                  "qSortByAscii": 1,
                  "qSortByLoadOrder": 1,
                  "qSortByExpression": 0,
                  "qExpression": {
                    "qv": ""
                  },
                  "qSortByGreyness": 0
                }
              ],
              "qNumberPresentations": [],
              "qReverseSort": false,
              "qActiveField": 0,
              "qLabelExpression": "='$(vD_Driver.Name)'",
              "autoSort": true,
              "cId": "PTfLLAx",
              "othersLabel": "Overige",
              "textAlign": {
                "auto": true,
                "align": "left"
              },
              "representation": {
                "type": "text",
                "urlLabel": ""
              }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
              "qOtherMode": "OTHER_OFF",
              "qOtherCounted": {
                "qv": "10"
              },
              "qOtherLimit": {
                "qv": "0"
              },
              "qOtherLimitMode": "OTHER_GE_LIMIT",
              "qSuppressOther": false,
              "qForceBadValueKeeping": true,
              "qApplyEvenWhenPossiblyWrongResult": true,
              "qGlobalOtherGrouping": false,
              "qOtherCollapseInnerDimensions": false,
              "qOtherSortMode": "OTHER_SORT_DESCENDING",
              "qTotalMode": "TOTAL_OFF",
              "qReferencedExpression": {
                "qv": ""
              }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
              "qv": "Overige"
            },
            "qTotalLabel": {
              "qv": ""
            },
            "qCalcCond": {
              "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        number:  {
            "qLibraryId": "",
            "qDef": {
              "qGrouping": "N",
              "qFieldDefs": [
                "[$(vD_Driver.Number)]"
              ],
              "qFieldLabels": [
                ""
              ],
              "qSortCriterias": [
                {
                  "qSortByState": 0,
                  "qSortByFrequency": 0,
                  "qSortByNumeric": 1,
                  "qSortByAscii": 1,
                  "qSortByLoadOrder": 1,
                  "qSortByExpression": 0,
                  "qExpression": {
                    "qv": ""
                  },
                  "qSortByGreyness": 0
                }
              ],
              "qNumberPresentations": [],
              "qReverseSort": false,
              "qActiveField": 0,
              "qLabelExpression": "='$(vD_Driver.Number)'",
              "autoSort": true,
              "cId": "TbJcWp",
              "othersLabel": "Overige",
              "textAlign": {
                "auto": true,
                "align": "left"
              },
              "representation": {
                "type": "text",
                "urlLabel": ""
              }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
              "qOtherMode": "OTHER_OFF",
              "qOtherCounted": {
                "qv": "10"
              },
              "qOtherLimit": {
                "qv": "0"
              },
              "qOtherLimitMode": "OTHER_GE_LIMIT",
              "qSuppressOther": false,
              "qForceBadValueKeeping": true,
              "qApplyEvenWhenPossiblyWrongResult": true,
              "qGlobalOtherGrouping": false,
              "qOtherCollapseInnerDimensions": false,
              "qOtherSortMode": "OTHER_SORT_DESCENDING",
              "qTotalMode": "TOTAL_OFF",
              "qReferencedExpression": {
                "qv": ""
              }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
              "qv": "Overige"
            },
            "qTotalLabel": {
              "qv": ""
            },
            "qCalcCond": {
              "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          }
    },
    trailer: {
        number: {
            "qLibraryId": "",
            "qDef": {
              "qGrouping": "N",
              "qFieldDefs": [
                "[$(vD_Trip.Trailer.Number)]"
              ],
              "qFieldLabels": [
                ""
              ],
              "qSortCriterias": [
                {
                  "qSortByState": 0,
                  "qSortByFrequency": 0,
                  "qSortByNumeric": 1,
                  "qSortByAscii": 1,
                  "qSortByLoadOrder": 1,
                  "qSortByExpression": 0,
                  "qExpression": {
                    "qv": ""
                  },
                  "qSortByGreyness": 0
                }
              ],
              "qNumberPresentations": [],
              "qReverseSort": true,
              "qActiveField": 0,
              "qLabelExpression": "='$(vD_Trip.Trailer.Number)'",
              "autoSort": true,
              "cId": "HYQARQ",
              "othersLabel": "Overige",
              "textAlign": {
                "auto": true,
                "align": "left"
              },
              "representation": {
                "type": "text",
                "urlLabel": ""
              }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
              "qOtherMode": "OTHER_OFF",
              "qOtherCounted": {
                "qv": "10"
              },
              "qOtherLimit": {
                "qv": "0"
              },
              "qOtherLimitMode": "OTHER_GE_LIMIT",
              "qSuppressOther": false,
              "qForceBadValueKeeping": true,
              "qApplyEvenWhenPossiblyWrongResult": true,
              "qGlobalOtherGrouping": false,
              "qOtherCollapseInnerDimensions": false,
              "qOtherSortMode": "OTHER_SORT_DESCENDING",
              "qTotalMode": "TOTAL_OFF",
              "qReferencedExpression": {
                "qv": ""
              }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
              "qv": "Overige"
            },
            "qTotalLabel": {
              "qv": ""
            },
            "qCalcCond": {
              "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        license:  {
            "qLibraryId": "",
            "qDef": {
              "qGrouping": "N",
              "qFieldDefs": [
                "[$(vD_Trip.Trailer.License)]"
              ],
              "qFieldLabels": [
                ""
              ],
              "qSortCriterias": [
                {
                  "qSortByState": 0,
                  "qSortByFrequency": 0,
                  "qSortByNumeric": 1,
                  "qSortByAscii": 1,
                  "qSortByLoadOrder": 1,
                  "qSortByExpression": 0,
                  "qExpression": {
                    "qv": ""
                  },
                  "qSortByGreyness": 0
                }
              ],
              "qNumberPresentations": [],
              "qReverseSort": true,
              "qActiveField": 0,
              "qLabelExpression": "='$(vD_Trip.Trailer.License)'",
              "autoSort": true,
              "cId": "HYQARQ",
              "othersLabel": "Overige",
              "textAlign": {
                "auto": true,
                "align": "left"
              },
              "representation": {
                "type": "text",
                "urlLabel": ""
              }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
              "qOtherMode": "OTHER_OFF",
              "qOtherCounted": {
                "qv": "10"
              },
              "qOtherLimit": {
                "qv": "0"
              },
              "qOtherLimitMode": "OTHER_GE_LIMIT",
              "qSuppressOther": false,
              "qForceBadValueKeeping": true,
              "qApplyEvenWhenPossiblyWrongResult": true,
              "qGlobalOtherGrouping": false,
              "qOtherCollapseInnerDimensions": false,
              "qOtherSortMode": "OTHER_SORT_DESCENDING",
              "qTotalMode": "TOTAL_OFF",
              "qReferencedExpression": {
                "qv": ""
              }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
              "qv": "Overige"
            },
            "qTotalLabel": {
              "qv": ""
            },
            "qCalcCond": {
              "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        name:  {
              "qLibraryId": "",
              "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                  "[$(vD_Trip.Trailer.Name)]"
                ],
                "qFieldLabels": [
                  ""
                ],
                "qSortCriterias": [
                  {
                    "qSortByState": 0,
                    "qSortByFrequency": 0,
                    "qSortByNumeric": 1,
                    "qSortByAscii": 1,
                    "qSortByLoadOrder": 1,
                    "qSortByExpression": 0,
                    "qExpression": {
                      "qv": ""
                    },
                    "qSortByGreyness": 0
                  }
                ],
                "qNumberPresentations": [],
                "qReverseSort": true,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Trip.Trailer.Name)'",
                "autoSort": true,
                "cId": "HYQARQ",
                "othersLabel": "Overige",
                "textAlign": {
                  "auto": true,
                  "align": "left"
                },
                "representation": {
                  "type": "text",
                  "urlLabel": ""
                }
              },
              "qNullSuppression": false,
              "qIncludeElemValue": false,
              "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                  "qv": "10"
                },
                "qOtherLimit": {
                  "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                  "qv": ""
                }
              },
              "qShowTotal": false,
              "qShowAll": false,
              "qOtherLabel": {
                "qv": "Overige"
              },
              "qTotalLabel": {
                "qv": ""
              },
              "qCalcCond": {
                "qv": ""
              },
              "qAttributeExpressions": [],
              "qAttributeDimensions": [],
              "qCalcCondition": {
                "qCond": {
                  "qv": ""
                },
                "qMsg": {
                  "qv": ""
                }
              }
            }
    },
    charter: {
        number: {
            "qLibraryId": "",
            "qDef": {
              "qGrouping": "N",
              "qFieldDefs": [
                "[$(vD_Trip.Creditor.Number)]"
              ],
              "qFieldLabels": [
                ""
              ],
              "qSortCriterias": [
                {
                  "qSortByState": 0,
                  "qSortByFrequency": 0,
                  "qSortByNumeric": 1,
                  "qSortByAscii": 1,
                  "qSortByLoadOrder": 1,
                  "qSortByExpression": 0,
                  "qExpression": {
                    "qv": ""
                  },
                  "qSortByGreyness": 0
                }
              ],
              "qNumberPresentations": [],
              "qReverseSort": true,
              "qActiveField": 0,
              "qLabelExpression": "='$(vD_Trip.Creditor.Number)'",
              "autoSort": true,
              "cId": "PppBJ",
              "othersLabel": "Overige",
              "textAlign": {
                "auto": true,
                "align": "left"
              },
              "representation": {
                "type": "text",
                "urlLabel": ""
              }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
              "qOtherMode": "OTHER_OFF",
              "qOtherCounted": {
                "qv": "10"
              },
              "qOtherLimit": {
                "qv": "0"
              },
              "qOtherLimitMode": "OTHER_GE_LIMIT",
              "qSuppressOther": false,
              "qForceBadValueKeeping": true,
              "qApplyEvenWhenPossiblyWrongResult": true,
              "qGlobalOtherGrouping": false,
              "qOtherCollapseInnerDimensions": false,
              "qOtherSortMode": "OTHER_SORT_DESCENDING",
              "qTotalMode": "TOTAL_OFF",
              "qReferencedExpression": {
                "qv": ""
              }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
              "qv": "Overige"
            },
            "qTotalLabel": {
              "qv": ""
            },
            "qCalcCond": {
              "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
        },
        name:  {
            "qLibraryId": "",
            "qDef": {
              "qGrouping": "N",
              "qFieldDefs": [
                "[$(vD_Trip.Creditor.Name)]"
              ],
              "qFieldLabels": [
                ""
              ],
              "qSortCriterias": [
                {
                  "qSortByState": 0,
                  "qSortByFrequency": 0,
                  "qSortByNumeric": 1,
                  "qSortByAscii": 1,
                  "qSortByLoadOrder": 1,
                  "qSortByExpression": 0,
                  "qExpression": {
                    "qv": ""
                  },
                  "qSortByGreyness": 0
                }
              ],
              "qNumberPresentations": [],
              "qReverseSort": false,
              "qActiveField": 0,
              "qLabelExpression": "='$(vD_Trip.Creditor.Name)'",
              "autoSort": true,
              "cId": "FpWZDHb",
              "othersLabel": "Overige",
              "textAlign": {
                "auto": true,
                "align": "left"
              },
              "representation": {
                "type": "text",
                "urlLabel": ""
              }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
              "qOtherMode": "OTHER_OFF",
              "qOtherCounted": {
                "qv": "10"
              },
              "qOtherLimit": {
                "qv": "0"
              },
              "qOtherLimitMode": "OTHER_GE_LIMIT",
              "qSuppressOther": false,
              "qForceBadValueKeeping": true,
              "qApplyEvenWhenPossiblyWrongResult": true,
              "qGlobalOtherGrouping": false,
              "qOtherCollapseInnerDimensions": false,
              "qOtherSortMode": "OTHER_SORT_DESCENDING",
              "qTotalMode": "TOTAL_OFF",
              "qReferencedExpression": {
                "qv": ""
              }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
              "qv": "Overige"
            },
            "qTotalLabel": {
              "qv": ""
            },
            "qCalcCond": {
              "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
        },
        searchcode:  {
            "qLibraryId": "",
            "qDef": {
              "qGrouping": "N",
              "qFieldDefs": [
                "[$(vD_Trip.Creditor.SearchCode)]"
              ],
              "qFieldLabels": [
                ""
              ],
              "qSortCriterias": [
                {
                  "qSortByState": 0,
                  "qSortByFrequency": 0,
                  "qSortByNumeric": 1,
                  "qSortByAscii": 1,
                  "qSortByLoadOrder": 1,
                  "qSortByExpression": 0,
                  "qExpression": {
                    "qv": ""
                  },
                  "qSortByGreyness": 0
                }
              ],
              "qNumberPresentations": [],
              "qReverseSort": false,
              "qActiveField": 0,
              "qLabelExpression": "='$(vD_Trip.Creditor.SearchCode)'",
              "autoSort": true,
              "cId": "FpWZDHb",
              "othersLabel": "Overige",
              "textAlign": {
                "auto": true,
                "align": "left"
              },
              "representation": {
                "type": "text",
                "urlLabel": ""
              }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
              "qOtherMode": "OTHER_OFF",
              "qOtherCounted": {
                "qv": "10"
              },
              "qOtherLimit": {
                "qv": "0"
              },
              "qOtherLimitMode": "OTHER_GE_LIMIT",
              "qSuppressOther": false,
              "qForceBadValueKeeping": true,
              "qApplyEvenWhenPossiblyWrongResult": true,
              "qGlobalOtherGrouping": false,
              "qOtherCollapseInnerDimensions": false,
              "qOtherSortMode": "OTHER_SORT_DESCENDING",
              "qTotalMode": "TOTAL_OFF",
              "qReferencedExpression": {
                "qv": ""
              }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
              "qv": "Overige"
            },
            "qTotalLabel": {
              "qv": ""
            },
            "qCalcCond": {
              "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
        },
        kinddescription:  {
            "qLibraryId": "",
            "qDef": {
              "qGrouping": "N",
              "qFieldDefs": [
                "[$(vD_Trip.Creditor.Kind.Description)]"
              ],
              "qFieldLabels": [
                ""
              ],
              "qSortCriterias": [
                {
                  "qSortByState": 0,
                  "qSortByFrequency": 0,
                  "qSortByNumeric": 1,
                  "qSortByAscii": 1,
                  "qSortByLoadOrder": 1,
                  "qSortByExpression": 0,
                  "qExpression": {
                    "qv": ""
                  },
                  "qSortByGreyness": 0
                }
              ],
              "qNumberPresentations": [],
              "qReverseSort": false,
              "qActiveField": 0,
              "qLabelExpression": "='$(vD_Trip.Creditor.Kind.Description)'",
              "autoSort": true,
              "cId": "FpWZDHb",
              "othersLabel": "Overige",
              "textAlign": {
                "auto": true,
                "align": "left"
              },
              "representation": {
                "type": "text",
                "urlLabel": ""
              }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
              "qOtherMode": "OTHER_OFF",
              "qOtherCounted": {
                "qv": "10"
              },
              "qOtherLimit": {
                "qv": "0"
              },
              "qOtherLimitMode": "OTHER_GE_LIMIT",
              "qSuppressOther": false,
              "qForceBadValueKeeping": true,
              "qApplyEvenWhenPossiblyWrongResult": true,
              "qGlobalOtherGrouping": false,
              "qOtherCollapseInnerDimensions": false,
              "qOtherSortMode": "OTHER_SORT_DESCENDING",
              "qTotalMode": "TOTAL_OFF",
              "qReferencedExpression": {
                "qv": ""
              }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
              "qv": "Overige"
            },
            "qTotalLabel": {
              "qv": ""
            },
            "qCalcCond": {
              "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
        },
        type:  {
            "qLibraryId": "",
            "qDef": {
              "qGrouping": "N",
              "qFieldDefs": [
                "[$(vD_Trip.Creditor.Type)]"
              ],
              "qFieldLabels": [
                ""
              ],
              "qSortCriterias": [
                {
                  "qSortByState": 0,
                  "qSortByFrequency": 0,
                  "qSortByNumeric": 1,
                  "qSortByAscii": 1,
                  "qSortByLoadOrder": 1,
                  "qSortByExpression": 0,
                  "qExpression": {
                    "qv": ""
                  },
                  "qSortByGreyness": 0
                }
              ],
              "qNumberPresentations": [],
              "qReverseSort": false,
              "qActiveField": 0,
              "qLabelExpression": "='$(vD_Trip.Creditor.Type)'",
              "autoSort": true,
              "cId": "FpWZDHa",
              "othersLabel": "Overige",
              "textAlign": {
                "auto": true,
                "align": "left"
              },
              "representation": {
                "type": "text",
                "urlLabel": ""
              }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
              "qOtherMode": "OTHER_OFF",
              "qOtherCounted": {
                "qv": "10"
              },
              "qOtherLimit": {
                "qv": "0"
              },
              "qOtherLimitMode": "OTHER_GE_LIMIT",
              "qSuppressOther": false,
              "qForceBadValueKeeping": true,
              "qApplyEvenWhenPossiblyWrongResult": true,
              "qGlobalOtherGrouping": false,
              "qOtherCollapseInnerDimensions": false,
              "qOtherSortMode": "OTHER_SORT_DESCENDING",
              "qTotalMode": "TOTAL_OFF",
              "qReferencedExpression": {
                "qv": ""
              }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
              "qv": "Overige"
            },
            "qTotalLabel": {
              "qv": ""
            },
            "qCalcCond": {
              "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
        }
    },
    trip: {
        number: {
            "qLibraryId": "",
            "qDef": {
              "qGrouping": "N",
              "qFieldDefs": [
                "[$(vD_Trip.TripNumber)]"
              ],
              "qFieldLabels": [
                ""
              ],
              "qSortCriterias": [
                {
                  "qSortByState": 0,
                  "qSortByFrequency": 0,
                  "qSortByNumeric": 1,
                  "qSortByAscii": 1,
                  "qSortByLoadOrder": 1,
                  "qSortByExpression": 0,
                  "qExpression": {
                    "qv": ""
                  },
                  "qSortByGreyness": 0
                }
              ],
              "qNumberPresentations": [],
              "qReverseSort": false,
              "qActiveField": 0,
              "qLabelExpression": "='$(vD_Trip.TripNumber)'",
              "autoSort": true,
              "cId": "AgsfAj",
              "othersLabel": "Overige",
              "textAlign": {
                "auto": true,
                "align": "left"
              },
              "representation": {
                "type": "text",
                "urlLabel": ""
              }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
              "qOtherMode": "OTHER_OFF",
              "qOtherCounted": {
                "qv": "10"
              },
              "qOtherLimit": {
                "qv": "0"
              },
              "qOtherLimitMode": "OTHER_GE_LIMIT",
              "qSuppressOther": false,
              "qForceBadValueKeeping": true,
              "qApplyEvenWhenPossiblyWrongResult": true,
              "qGlobalOtherGrouping": false,
              "qOtherCollapseInnerDimensions": false,
              "qOtherSortMode": "OTHER_SORT_DESCENDING",
              "qTotalMode": "TOTAL_OFF",
              "qReferencedExpression": {
                "qv": ""
              }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
              "qv": "Overige"
            },
            "qTotalLabel": {
              "qv": ""
            },
            "qCalcCond": {
              "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
        },
        resourcecombination:  {
            "qLibraryId": "",
            "qDef": {
              "qGrouping": "N",
              "qFieldDefs": [
                "[$(vD_Trip.ResourceCombination)]"
              ],
              "qFieldLabels": [
                ""
              ],
              "qSortCriterias": [
                {
                  "qSortByState": 0,
                  "qSortByFrequency": 0,
                  "qSortByNumeric": 1,
                  "qSortByAscii": 1,
                  "qSortByLoadOrder": 1,
                  "qSortByExpression": 0,
                  "qExpression": {
                    "qv": ""
                  },
                  "qSortByGreyness": 0
                }
              ],
              "qNumberPresentations": [],
              "qReverseSort": false,
              "qActiveField": 0,
              "qLabelExpression": "='$(vD_Trip.ResourceCombination)'",
              "autoSort": true,
              "cId": "AgsfAj",
              "othersLabel": "Overige",
              "textAlign": {
                "auto": true,
                "align": "left"
              },
              "representation": {
                "type": "text",
                "urlLabel": ""
              }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
              "qOtherMode": "OTHER_OFF",
              "qOtherCounted": {
                "qv": "10"
              },
              "qOtherLimit": {
                "qv": "0"
              },
              "qOtherLimitMode": "OTHER_GE_LIMIT",
              "qSuppressOther": false,
              "qForceBadValueKeeping": true,
              "qApplyEvenWhenPossiblyWrongResult": true,
              "qGlobalOtherGrouping": false,
              "qOtherCollapseInnerDimensions": false,
              "qOtherSortMode": "OTHER_SORT_DESCENDING",
              "qTotalMode": "TOTAL_OFF",
              "qReferencedExpression": {
                "qv": ""
              }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
              "qv": "Overige"
            },
            "qTotalLabel": {
              "qv": ""
            },
            "qCalcCond": {
              "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
        },
        companyname:  {
            "qLibraryId": "",
            "qDef": {
              "qGrouping": "N",
              "qFieldDefs": [
                "[$(vD_Trip.Company.Name)]"
              ],
              "qFieldLabels": [
                ""
              ],
              "qSortCriterias": [
                {
                  "qSortByState": 0,
                  "qSortByFrequency": 0,
                  "qSortByNumeric": 1,
                  "qSortByAscii": 1,
                  "qSortByLoadOrder": 1,
                  "qSortByExpression": 0,
                  "qExpression": {
                    "qv": ""
                  },
                  "qSortByGreyness": 0
                }
              ],
              "qNumberPresentations": [],
              "qReverseSort": false,
              "qActiveField": 0,
              "qLabelExpression": "='$(vD_Trip.Company.Name)'",
              "autoSort": true,
              "cId": "AgsfAj",
              "othersLabel": "Overige",
              "textAlign": {
                "auto": true,
                "align": "left"
              },
              "representation": {
                "type": "text",
                "urlLabel": ""
              }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
              "qOtherMode": "OTHER_OFF",
              "qOtherCounted": {
                "qv": "10"
              },
              "qOtherLimit": {
                "qv": "0"
              },
              "qOtherLimitMode": "OTHER_GE_LIMIT",
              "qSuppressOther": false,
              "qForceBadValueKeeping": true,
              "qApplyEvenWhenPossiblyWrongResult": true,
              "qGlobalOtherGrouping": false,
              "qOtherCollapseInnerDimensions": false,
              "qOtherSortMode": "OTHER_SORT_DESCENDING",
              "qTotalMode": "TOTAL_OFF",
              "qReferencedExpression": {
                "qv": ""
              }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
              "qv": "Overige"
            },
            "qTotalLabel": {
              "qv": ""
            },
            "qCalcCond": {
              "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        intercompany:  {
            "qLibraryId": "",
            "qDef": {
              "qGrouping": "N",
              "qFieldDefs": [
                "[$(vD_Trip.Company.Intercompany)]"
              ],
              "qFieldLabels": [
                ""
              ],
              "qSortCriterias": [
                {
                  "qSortByState": 0,
                  "qSortByFrequency": 0,
                  "qSortByNumeric": 1,
                  "qSortByAscii": 1,
                  "qSortByLoadOrder": 1,
                  "qSortByExpression": 0,
                  "qExpression": {
                    "qv": ""
                  },
                  "qSortByGreyness": 0
                }
              ],
              "qNumberPresentations": [],
              "qReverseSort": false,
              "qActiveField": 0,
              "qLabelExpression": "='$(vD_Trip.Company.Intercompany)'",
              "autoSort": true,
              "cId": "AgsfAj",
              "othersLabel": "Overige",
              "textAlign": {
                "auto": true,
                "align": "left"
              },
              "representation": {
                "type": "text",
                "urlLabel": ""
              }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
              "qOtherMode": "OTHER_OFF",
              "qOtherCounted": {
                "qv": "10"
              },
              "qOtherLimit": {
                "qv": "0"
              },
              "qOtherLimitMode": "OTHER_GE_LIMIT",
              "qSuppressOther": false,
              "qForceBadValueKeeping": true,
              "qApplyEvenWhenPossiblyWrongResult": true,
              "qGlobalOtherGrouping": false,
              "qOtherCollapseInnerDimensions": false,
              "qOtherSortMode": "OTHER_SORT_DESCENDING",
              "qTotalMode": "TOTAL_OFF",
              "qReferencedExpression": {
                "qv": ""
              }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
              "qv": "Overige"
            },
            "qTotalLabel": {
              "qv": ""
            },
            "qCalcCond": {
              "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        nrofcodrivers:  {
            "qLibraryId": "",
            "qDef": {
              "qGrouping": "N",
              "qFieldDefs": [
                "[$(vD_Trip.NrOfCoDriver)]"
              ],
              "qFieldLabels": [
                ""
              ],
              "qSortCriterias": [
                {
                  "qSortByState": 0,
                  "qSortByFrequency": 0,
                  "qSortByNumeric": 1,
                  "qSortByAscii": 1,
                  "qSortByLoadOrder": 1,
                  "qSortByExpression": 0,
                  "qExpression": {
                    "qv": ""
                  },
                  "qSortByGreyness": 0
                }
              ],
              "qNumberPresentations": [],
              "qReverseSort": false,
              "qActiveField": 0,
              "qLabelExpression": "='$(vD_Trip.NrOfCoDriver)'",
              "autoSort": true,
              "cId": "dYGPH",
              "othersLabel": "Overige",
              "textAlign": {
                "auto": true,
                "align": "left"
              },
              "representation": {
                "type": "text",
                "urlLabel": ""
              }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
              "qOtherMode": "OTHER_OFF",
              "qOtherCounted": {
                "qv": "10"
              },
              "qOtherLimit": {
                "qv": "0"
              },
              "qOtherLimitMode": "OTHER_GE_LIMIT",
              "qSuppressOther": false,
              "qForceBadValueKeeping": true,
              "qApplyEvenWhenPossiblyWrongResult": true,
              "qGlobalOtherGrouping": false,
              "qOtherCollapseInnerDimensions": false,
              "qOtherSortMode": "OTHER_SORT_DESCENDING",
              "qTotalMode": "TOTAL_OFF",
              "qReferencedExpression": {
                "qv": ""
              }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
              "qv": "Overige"
            },
            "qTotalLabel": {
              "qv": ""
            },
            "qCalcCond": {
              "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },      
        plangroup:  {
            "qLibraryId": "",
            "qDef": {
              "qGrouping": "N",
              "qFieldDefs": [
                "[$(vD_Trip.PlanGroup)]"
              ],
              "qFieldLabels": [
                ""
              ],
              "qSortCriterias": [
                {
                  "qSortByState": 0,
                  "qSortByFrequency": 0,
                  "qSortByNumeric": 1,
                  "qSortByAscii": 1,
                  "qSortByLoadOrder": 1,
                  "qSortByExpression": 0,
                  "qExpression": {
                    "qv": ""
                  },
                  "qSortByGreyness": 0
                }
              ],
              "qNumberPresentations": [],
              "qReverseSort": false,
              "qActiveField": 0,
              "qLabelExpression": "='$(vD_Trip.PlanGroup)'",
              "autoSort": true,
              "othersLabel": "Overige",
              "textAlign": {
                "auto": true,
                "align": "left"
              },
              "representation": {
                "type": "text",
                "urlLabel": ""
              }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
              "qOtherMode": "OTHER_OFF",
              "qOtherCounted": {
                "qv": "10"
              },
              "qOtherLimit": {
                "qv": "0"
              },
              "qOtherLimitMode": "OTHER_GE_LIMIT",
              "qSuppressOther": false,
              "qForceBadValueKeeping": true,
              "qApplyEvenWhenPossiblyWrongResult": true,
              "qGlobalOtherGrouping": false,
              "qOtherCollapseInnerDimensions": false,
              "qOtherSortMode": "OTHER_SORT_DESCENDING",
              "qTotalMode": "TOTAL_OFF",
              "qReferencedExpression": {
                "qv": ""
              }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
              "qv": "Overige"
            },
            "qTotalLabel": {
              "qv": ""
            },
            "qCalcCond": {
              "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        plandivision:  {
            "qLibraryId": "",
            "qDef": {
              "qGrouping": "N",
              "qFieldDefs": [
                "[$(vD_Trip.PlanDivision)]"
              ],
              "qFieldLabels": [
                ""
              ],
              "qSortCriterias": [
                {
                  "qSortByState": 0,
                  "qSortByFrequency": 0,
                  "qSortByNumeric": 1,
                  "qSortByAscii": 1,
                  "qSortByLoadOrder": 1,
                  "qSortByExpression": 0,
                  "qExpression": {
                    "qv": ""
                  },
                  "qSortByGreyness": 0
                }
              ],
              "qNumberPresentations": [],
              "qReverseSort": false,
              "qActiveField": 0,
              "qLabelExpression": "='$(vD_Trip.PlanDivision)'",
              "autoSort": true,
              "othersLabel": "Overige",
              "textAlign": {
                "auto": true,
                "align": "left"
              },
              "representation": {
                "type": "text",
                "urlLabel": ""
              }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
              "qOtherMode": "OTHER_OFF",
              "qOtherCounted": {
                "qv": "10"
              },
              "qOtherLimit": {
                "qv": "0"
              },
              "qOtherLimitMode": "OTHER_GE_LIMIT",
              "qSuppressOther": false,
              "qForceBadValueKeeping": true,
              "qApplyEvenWhenPossiblyWrongResult": true,
              "qGlobalOtherGrouping": false,
              "qOtherCollapseInnerDimensions": false,
              "qOtherSortMode": "OTHER_SORT_DESCENDING",
              "qTotalMode": "TOTAL_OFF",
              "qReferencedExpression": {
                "qv": ""
              }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
              "qv": "Overige"
            },
            "qTotalLabel": {
              "qv": ""
            },
            "qCalcCond": {
              "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        planregio:  {
            "qLibraryId": "",
            "qDef": {
              "qGrouping": "N",
              "qFieldDefs": [
                "[$(vD_Trip.PlanRegio)]"
              ],
              "qFieldLabels": [
                ""
              ],
              "qSortCriterias": [
                {
                  "qSortByState": 0,
                  "qSortByFrequency": 0,
                  "qSortByNumeric": 1,
                  "qSortByAscii": 1,
                  "qSortByLoadOrder": 1,
                  "qSortByExpression": 0,
                  "qExpression": {
                    "qv": ""
                  },
                  "qSortByGreyness": 0
                }
              ],
              "qNumberPresentations": [],
              "qReverseSort": false,
              "qActiveField": 0,
              "qLabelExpression": "='$(vD_Trip.PlanRegio)'",
              "autoSort": true,
              "othersLabel": "Overige",
              "textAlign": {
                "auto": true,
                "align": "left"
              },
              "representation": {
                "type": "text",
                "urlLabel": ""
              }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
              "qOtherMode": "OTHER_OFF",
              "qOtherCounted": {
                "qv": "10"
              },
              "qOtherLimit": {
                "qv": "0"
              },
              "qOtherLimitMode": "OTHER_GE_LIMIT",
              "qSuppressOther": false,
              "qForceBadValueKeeping": true,
              "qApplyEvenWhenPossiblyWrongResult": true,
              "qGlobalOtherGrouping": false,
              "qOtherCollapseInnerDimensions": false,
              "qOtherSortMode": "OTHER_SORT_DESCENDING",
              "qTotalMode": "TOTAL_OFF",
              "qReferencedExpression": {
                "qv": ""
              }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
              "qv": "Overige"
            },
            "qTotalLabel": {
              "qv": ""
            },
            "qCalcCond": {
              "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          }
    },
    startlocation: {
        id: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "[$(vD_Trip.StartLocation.ID)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Trip.StartLocation.ID)'",
                "autoSort": true,
                "othersLabel": "Overige",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Overige"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        name: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "[$(vD_Trip.StartLocation.Name)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Trip.StartLocation.Name)'",
                "autoSort": true,
                "othersLabel": "Overige",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Overige"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        adress: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "[$(vD_Trip.StartLocation.Adress)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Trip.StartLocation.Adress)'",
                "autoSort": true,
                "othersLabel": "Overige",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Overige"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        searchcode: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "[$(vD_Trip.StartLocation.SearchCode)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Trip.StartLocation.SearchCode)'",
                "autoSort": true,
                "othersLabel": "Overige",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Overige"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        zipcode: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "[$(vD_Trip.StartLocation.ZIPcode)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Trip.StartLocation.ZIPcode)'",
                "autoSort": true,
                "othersLabel": "Overige",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Overige"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        city: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "[$(vD_Trip.StartLocation.City)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Trip.StartLocation.City)'",
                "autoSort": true,
                "othersLabel": "Overige",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Overige"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        district: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "=[$(vD_Trip.StartLocation.District)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Trip.StartLocation.District)'",
                "autoSort": true,
                "othersLabel": "Others",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Others"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        province: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "=[$(vD_Trip.StartLocation.Province)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Trip.StartLocation.Province)'",
                "autoSort": true,
                "othersLabel": "Others",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Others"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        region: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "=[$(vD_Trip.StartLocation.Region)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Trip.StartLocation.Region)'",
                "autoSort": true,
                "othersLabel": "Others",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Others"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        financialregion: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "=[$(vD_Trip.StartLocation.FinancialRegion)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Trip.StartLocation.FinancialRegion)'",
                "autoSort": true,
                "othersLabel": "Others",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Others"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        financialregiondescription: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "=[$(vD_Trip.StartLocation.FinancialRegionDescription)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Trip.StartLocation.FinancialRegionDescription)'",
                "autoSort": true,
                "othersLabel": "Others",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Others"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        operationalregion: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "=[$(vD_Trip.StartLocation.OperationalRegion)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Trip.StartLocation.OperationalRegion)'",
                "autoSort": true,
                "othersLabel": "Others",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Others"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        operationalregiondescription: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "=[$(vD_Trip.StartLocation.OperationalRegionDescription)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Trip.StartLocation.OperationalRegionDescription)'",
                "autoSort": true,
                "othersLabel": "Others",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Others"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        country: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "=[$(vD_Trip.StartLocation.Country)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Trip.StartLocation.Country)'",
                "autoSort": true,
                "othersLabel": "Overige",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Overige"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        land: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "=[$(vD_Trip.StartLocation.Land)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Trip.StartLocation.Land)'",
                "autoSort": true,
                "othersLabel": "Overige",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Overige"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
    },
    endlocation: {
        id: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "[$(vD_Trip.EndLocation.ID)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Trip.EndLocation.ID)'",
                "autoSort": true,
                "othersLabel": "Overige",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Overige"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        name: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "[$(vD_Trip.EndLocation.Name)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Trip.EndLocation.Name)'",
                "autoSort": true,
                "othersLabel": "Overige",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Overige"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        adress: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "[$(vD_Trip.EndLocation.Adress)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Trip.EndLocation.Adress)'",
                "autoSort": true,
                "othersLabel": "Overige",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Overige"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        searchcode: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "[$(vD_Trip.EndLocation.SearchCode)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Trip.EndLocation.SearchCode)'",
                "autoSort": true,
                "othersLabel": "Overige",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Overige"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        zipcode: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "[$(vD_Trip.EndLocation.ZIPcode)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Trip.EndLocation.ZIPcode)'",
                "autoSort": true,
                "othersLabel": "Overige",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Overige"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        city: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "[$(vD_Trip.EndLocation.City)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Trip.EndLocation.City)'",
                "autoSort": true,
                "othersLabel": "Overige",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Overige"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        district: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "=[$(vD_Trip.EndLocation.District)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Trip.EndLocation.District)'",
                "autoSort": true,
                "othersLabel": "Others",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Others"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        province: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "=[$(vD_Trip.EndLocation.Province)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Trip.EndLocation.Province)'",
                "autoSort": true,
                "othersLabel": "Others",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Others"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        region: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "=[$(vD_Trip.EndLocation.Region)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Trip.EndLocation.Region)'",
                "autoSort": true,
                "othersLabel": "Others",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Others"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        financialregion: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "=[$(vD_Trip.EndLocation.FinancialRegion)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Trip.EndLocation.FinancialRegion)'",
                "autoSort": true,
                "othersLabel": "Others",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Others"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        financialregiondescription: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "=[$(vD_Trip.EndLocation.FinancialRegionDescription)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Trip.EndLocation.FinancialRegionDescription)'",
                "autoSort": true,
                "othersLabel": "Others",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Others"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        operationalregion: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "=[$(vD_Trip.EndLocation.OperationalRegion)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Trip.EndLocation.OperationalRegion)'",
                "autoSort": true,
                "othersLabel": "Others",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Others"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        operationalregiondescription: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "=[$(vD_Trip.EndLocation.OperationalRegionDescription)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Trip.EndLocation.OperationalRegionDescription)'",
                "autoSort": true,
                "othersLabel": "Others",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Others"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        country: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "=[$(vD_Trip.EndLocation.Country)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Trip.EndLocation.Country)'",
                "autoSort": true,
                "othersLabel": "Overige",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Overige"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
        land: {
            "qLibraryId": "",
            "qDef": {
                "qGrouping": "N",
                "qFieldDefs": [
                    "=[$(vD_Trip.EndLocation.Land)]"
                ],
                "qFieldLabels": [
                    ""
                ],
                "qSortCriterias": [
                    {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": {
                            "qv": ""
                        },
                        "qSortByGreyness": 0
                    }
                ],
                "qNumberPresentations": [],
                "qReverseSort": false,
                "qActiveField": 0,
                "qLabelExpression": "='$(vD_Trip.EndLocation.Land)'",
                "autoSort": true,
                "othersLabel": "Overige",
                "textAlign": {
                    "auto": true,
                    "align": "left"
                },
                "representation": {
                    "type": "text",
                    "urlLabel": ""
                }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
                "qOtherMode": "OTHER_OFF",
                "qOtherCounted": {
                    "qv": "10"
                },
                "qOtherLimit": {
                    "qv": "0"
                },
                "qOtherLimitMode": "OTHER_GE_LIMIT",
                "qSuppressOther": false,
                "qForceBadValueKeeping": true,
                "qApplyEvenWhenPossiblyWrongResult": true,
                "qGlobalOtherGrouping": false,
                "qOtherCollapseInnerDimensions": false,
                "qOtherSortMode": "OTHER_SORT_DESCENDING",
                "qTotalMode": "TOTAL_OFF",
                "qReferencedExpression": {
                    "qv": ""
                }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
                "qv": "Overige"
            },
            "qTotalLabel": {
                "qv": ""
            },
            "qCalcCond": {
                "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
                "qCond": {
                    "qv": ""
                },
                "qMsg": {
                    "qv": ""
                }
            }
        },
    },
    period: {
        year: {
            "qLibraryId": "",
            "qDef": {
              "qGrouping": "N",
              "qFieldDefs": [
                "[$(vD_Year)]"
              ],
              "qFieldLabels": [
                ""
              ],
              "qSortCriterias": [
                {
                  "qSortByState": 0,
                  "qSortByFrequency": 0,
                  "qSortByNumeric": 1,
                  "qSortByAscii": 1,
                  "qSortByLoadOrder": 1,
                  "qSortByExpression": 0,
                  "qExpression": {
                    "qv": ""
                  },
                  "qSortByGreyness": 0
                }
              ],
              "qNumberPresentations": [],
              "qReverseSort": false,
              "qActiveField": 0,
              "qLabelExpression": "='$(vD_Year)'",
              "autoSort": true,
              "othersLabel": "Overige",
              "textAlign": {
                "auto": true,
                "align": "left"
              },
              "representation": {
                "type": "text",
                "urlLabel": ""
              }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
              "qOtherMode": "OTHER_OFF",
              "qOtherCounted": {
                "qv": "10"
              },
              "qOtherLimit": {
                "qv": "0"
              },
              "qOtherLimitMode": "OTHER_GE_LIMIT",
              "qSuppressOther": false,
              "qForceBadValueKeeping": true,
              "qApplyEvenWhenPossiblyWrongResult": true,
              "qGlobalOtherGrouping": false,
              "qOtherCollapseInnerDimensions": false,
              "qOtherSortMode": "OTHER_SORT_DESCENDING",
              "qTotalMode": "TOTAL_OFF",
              "qReferencedExpression": {
                "qv": ""
              }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
              "qv": "Overige"
            },
            "qTotalLabel": {
              "qv": ""
            },
            "qCalcCond": {
              "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        quarter:  {
            "qLibraryId": "",
            "qDef": {
              "qGrouping": "N",
              "qFieldDefs": [
                "[$(vD_Quarter)]"
              ],
              "qFieldLabels": [
                ""
              ],
              "qSortCriterias": [
                {
                  "qSortByState": 0,
                  "qSortByFrequency": 0,
                  "qSortByNumeric": 1,
                  "qSortByAscii": 1,
                  "qSortByLoadOrder": 1,
                  "qSortByExpression": 0,
                  "qExpression": {
                    "qv": ""
                  },
                  "qSortByGreyness": 0
                }
              ],
              "qNumberPresentations": [],
              "qReverseSort": false,
              "qActiveField": 0,
              "qLabelExpression": "='$(vD_Quarter)'",
              "autoSort": true,
              "othersLabel": "Overige",
              "textAlign": {
                "auto": true,
                "align": "left"
              },
              "representation": {
                "type": "text",
                "urlLabel": ""
              }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
              "qOtherMode": "OTHER_OFF",
              "qOtherCounted": {
                "qv": "10"
              },
              "qOtherLimit": {
                "qv": "0"
              },
              "qOtherLimitMode": "OTHER_GE_LIMIT",
              "qSuppressOther": false,
              "qForceBadValueKeeping": true,
              "qApplyEvenWhenPossiblyWrongResult": true,
              "qGlobalOtherGrouping": false,
              "qOtherCollapseInnerDimensions": false,
              "qOtherSortMode": "OTHER_SORT_DESCENDING",
              "qTotalMode": "TOTAL_OFF",
              "qReferencedExpression": {
                "qv": ""
              }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
              "qv": "Overige"
            },
            "qTotalLabel": {
              "qv": ""
            },
            "qCalcCond": {
              "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        month:  {
            "qLibraryId": "",
            "qDef": {
              "qGrouping": "N",
              "qFieldDefs": [
                "[$(vD_Month)]"
              ],
              "qFieldLabels": [
                ""
              ],
              "qSortCriterias": [
                {
                  "qSortByState": 0,
                  "qSortByFrequency": 0,
                  "qSortByNumeric": 1,
                  "qSortByAscii": 1,
                  "qSortByLoadOrder": 1,
                  "qSortByExpression": 0,
                  "qExpression": {
                    "qv": ""
                  },
                  "qSortByGreyness": 0
                }
              ],
              "qNumberPresentations": [],
              "qReverseSort": false,
              "qActiveField": 0,
              "qLabelExpression": "='$(vD_Month)'",
              "autoSort": true,
              "othersLabel": "Overige",
              "textAlign": {
                "auto": true,
                "align": "left"
              },
              "representation": {
                "type": "text",
                "urlLabel": ""
              }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
              "qOtherMode": "OTHER_OFF",
              "qOtherCounted": {
                "qv": "10"
              },
              "qOtherLimit": {
                "qv": "0"
              },
              "qOtherLimitMode": "OTHER_GE_LIMIT",
              "qSuppressOther": false,
              "qForceBadValueKeeping": true,
              "qApplyEvenWhenPossiblyWrongResult": true,
              "qGlobalOtherGrouping": false,
              "qOtherCollapseInnerDimensions": false,
              "qOtherSortMode": "OTHER_SORT_DESCENDING",
              "qTotalMode": "TOTAL_OFF",
              "qReferencedExpression": {
                "qv": ""
              }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
              "qv": "Overige"
            },
            "qTotalLabel": {
              "qv": ""
            },
            "qCalcCond": {
              "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        period:  {
            "qLibraryId": "",
            "qDef": {
              "qGrouping": "N",
              "qFieldDefs": [
                "[$(vD_Period)]"
              ],
              "qFieldLabels": [
                ""
              ],
              "qSortCriterias": [
                {
                  "qSortByState": 0,
                  "qSortByFrequency": 0,
                  "qSortByNumeric": 1,
                  "qSortByAscii": 1,
                  "qSortByLoadOrder": 1,
                  "qSortByExpression": 0,
                  "qExpression": {
                    "qv": ""
                  },
                  "qSortByGreyness": 0
                }
              ],
              "qNumberPresentations": [],
              "qReverseSort": false,
              "qActiveField": 0,
              "qLabelExpression": "='$(vD_Period)'",
              "autoSort": true,
              "othersLabel": "Overige",
              "textAlign": {
                "auto": true,
                "align": "left"
              },
              "representation": {
                "type": "text",
                "urlLabel": ""
              }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
              "qOtherMode": "OTHER_OFF",
              "qOtherCounted": {
                "qv": "10"
              },
              "qOtherLimit": {
                "qv": "0"
              },
              "qOtherLimitMode": "OTHER_GE_LIMIT",
              "qSuppressOther": false,
              "qForceBadValueKeeping": true,
              "qApplyEvenWhenPossiblyWrongResult": true,
              "qGlobalOtherGrouping": false,
              "qOtherCollapseInnerDimensions": false,
              "qOtherSortMode": "OTHER_SORT_DESCENDING",
              "qTotalMode": "TOTAL_OFF",
              "qReferencedExpression": {
                "qv": ""
              }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
              "qv": "Overige"
            },
            "qTotalLabel": {
              "qv": ""
            },
            "qCalcCond": {
              "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        week:  {
            "qLibraryId": "",
            "qDef": {
              "qGrouping": "N",
              "qFieldDefs": [
                "[$(vD_Week)]"
              ],
              "qFieldLabels": [
                ""
              ],
              "qSortCriterias": [
                {
                  "qSortByState": 0,
                  "qSortByFrequency": 0,
                  "qSortByNumeric": 1,
                  "qSortByAscii": 1,
                  "qSortByLoadOrder": 1,
                  "qSortByExpression": 0,
                  "qExpression": {
                    "qv": ""
                  },
                  "qSortByGreyness": 0
                }
              ],
              "qNumberPresentations": [],
              "qReverseSort": false,
              "qActiveField": 0,
              "qLabelExpression": "='$(vD_Week)'",
              "autoSort": true,
              "othersLabel": "Overige",
              "textAlign": {
                "auto": true,
                "align": "left"
              },
              "representation": {
                "type": "text",
                "urlLabel": ""
              }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
              "qOtherMode": "OTHER_OFF",
              "qOtherCounted": {
                "qv": "10"
              },
              "qOtherLimit": {
                "qv": "0"
              },
              "qOtherLimitMode": "OTHER_GE_LIMIT",
              "qSuppressOther": false,
              "qForceBadValueKeeping": true,
              "qApplyEvenWhenPossiblyWrongResult": true,
              "qGlobalOtherGrouping": false,
              "qOtherCollapseInnerDimensions": false,
              "qOtherSortMode": "OTHER_SORT_DESCENDING",
              "qTotalMode": "TOTAL_OFF",
              "qReferencedExpression": {
                "qv": ""
              }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
              "qv": "Overige"
            },
            "qTotalLabel": {
              "qv": ""
            },
            "qCalcCond": {
              "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        yearweek:  {
            "qLibraryId": "",
            "qDef": {
              "qGrouping": "N",
              "qFieldDefs": [
                "[$(vD_YearWeek)]"
              ],
              "qFieldLabels": [
                ""
              ],
              "qSortCriterias": [
                {
                  "qSortByState": 0,
                  "qSortByFrequency": 0,
                  "qSortByNumeric": 1,
                  "qSortByAscii": 1,
                  "qSortByLoadOrder": 1,
                  "qSortByExpression": 0,
                  "qExpression": {
                    "qv": ""
                  },
                  "qSortByGreyness": 0
                }
              ],
              "qNumberPresentations": [],
              "qReverseSort": false,
              "qActiveField": 0,
              "qLabelExpression": "='$(vD_YearWeek)'",
              "autoSort": true,
              "othersLabel": "Overige",
              "textAlign": {
                "auto": true,
                "align": "left"
              },
              "representation": {
                "type": "text",
                "urlLabel": ""
              }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
              "qOtherMode": "OTHER_OFF",
              "qOtherCounted": {
                "qv": "10"
              },
              "qOtherLimit": {
                "qv": "0"
              },
              "qOtherLimitMode": "OTHER_GE_LIMIT",
              "qSuppressOther": false,
              "qForceBadValueKeeping": true,
              "qApplyEvenWhenPossiblyWrongResult": true,
              "qGlobalOtherGrouping": false,
              "qOtherCollapseInnerDimensions": false,
              "qOtherSortMode": "OTHER_SORT_DESCENDING",
              "qTotalMode": "TOTAL_OFF",
              "qReferencedExpression": {
                "qv": ""
              }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
              "qv": "Overige"
            },
            "qTotalLabel": {
              "qv": ""
            },
            "qCalcCond": {
              "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        date:  {
            "qLibraryId": "",
            "qDef": {
              "qGrouping": "N",
              "qFieldDefs": [
                "[$(vD_Date)]"
              ],
              "qFieldLabels": [
                ""
              ],
              "qSortCriterias": [
                {
                  "qSortByState": 0,
                  "qSortByFrequency": 0,
                  "qSortByNumeric": 1,
                  "qSortByAscii": 1,
                  "qSortByLoadOrder": 1,
                  "qSortByExpression": 0,
                  "qExpression": {
                    "qv": ""
                  },
                  "qSortByGreyness": 0
                }
              ],
              "qNumberPresentations": [],
              "qReverseSort": false,
              "qActiveField": 0,
              "qLabelExpression": "='$(vD_Date)'",
              "autoSort": true,
              "othersLabel": "Overige",
              "textAlign": {
                "auto": true,
                "align": "left"
              },
              "representation": {
                "type": "text",
                "urlLabel": ""
              }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
              "qOtherMode": "OTHER_OFF",
              "qOtherCounted": {
                "qv": "10"
              },
              "qOtherLimit": {
                "qv": "0"
              },
              "qOtherLimitMode": "OTHER_GE_LIMIT",
              "qSuppressOther": false,
              "qForceBadValueKeeping": true,
              "qApplyEvenWhenPossiblyWrongResult": true,
              "qGlobalOtherGrouping": false,
              "qOtherCollapseInnerDimensions": false,
              "qOtherSortMode": "OTHER_SORT_DESCENDING",
              "qTotalMode": "TOTAL_OFF",
              "qReferencedExpression": {
                "qv": ""
              }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
              "qv": "Overige"
            },
            "qTotalLabel": {
              "qv": ""
            },
            "qCalcCond": {
              "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        weekday:  {
            "qLibraryId": "",
            "qDef": {
              "qGrouping": "N",
              "qFieldDefs": [
                "[$(vD_Weekday)]"
              ],
              "qFieldLabels": [
                ""
              ],
              "qSortCriterias": [
                {
                  "qSortByState": 0,
                  "qSortByFrequency": 0,
                  "qSortByNumeric": 1,
                  "qSortByAscii": 1,
                  "qSortByLoadOrder": 1,
                  "qSortByExpression": 0,
                  "qExpression": {
                    "qv": ""
                  },
                  "qSortByGreyness": 0
                }
              ],
              "qNumberPresentations": [],
              "qReverseSort": false,
              "qActiveField": 0,
              "qLabelExpression": "='$(vD_Weekday)'",
              "autoSort": true,
              "othersLabel": "Overige",
              "textAlign": {
                "auto": true,
                "align": "left"
              },
              "representation": {
                "type": "text",
                "urlLabel": ""
              }
            },
            "qNullSuppression": false,
            "qIncludeElemValue": false,
            "qOtherTotalSpec": {
              "qOtherMode": "OTHER_OFF",
              "qOtherCounted": {
                "qv": "10"
              },
              "qOtherLimit": {
                "qv": "0"
              },
              "qOtherLimitMode": "OTHER_GE_LIMIT",
              "qSuppressOther": false,
              "qForceBadValueKeeping": true,
              "qApplyEvenWhenPossiblyWrongResult": true,
              "qGlobalOtherGrouping": false,
              "qOtherCollapseInnerDimensions": false,
              "qOtherSortMode": "OTHER_SORT_DESCENDING",
              "qTotalMode": "TOTAL_OFF",
              "qReferencedExpression": {
                "qv": ""
              }
            },
            "qShowTotal": false,
            "qShowAll": false,
            "qOtherLabel": {
              "qv": "Overige"
            },
            "qTotalLabel": {
              "qv": ""
            },
            "qCalcCond": {
              "qv": ""
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          }
    }
};

const measures = {
    omzet: {
        totaal: {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "TripOmzet",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_TripOmzet)",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_TripOmzet)' & ' > ' & '$(vB_Alle)'",
              "autoSort": true,
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        freight:   {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "TripOmzet",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_TripOmzet(Kind={'Freight'}))",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_TripOmzet)' & ' > ' & '$(vB_Freight)'",
              "autoSort": true,
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        toll:   {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "OrderOmzet",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_TripOmzet(Kind={'Fuel'}))",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_TripOmzet)' & ' > ' & '$(vB_Fuel)'",
              "autoSort": true,
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        fuel: {
          "qLibraryId": "",
          "qDef": {
            "qLabel": "OrderOmzet",
            "qDescription": "",
            "qTags": [],
            "qGrouping": "N",
            "qDef": "$(vE_TripOmzet(Kind={'Fuel'}))",
            "qNumFormat": {
              "qType": "U",
              "qnDec": 10,
              "qUseThou": 0,
              "qFmt": "",
              "qDec": "",
              "qThou": ""
            },
            "qRelative": false,
            "qBrutalSum": false,
            "qAggrFunc": "Expr",
            "qAccumulate": 0,
            "qReverseSort": false,
            "qActiveExpression": 0,
            "qExpressions": [],
            "qLabelExpression": "='$(vL_TripOmzet)' & ' > ' & '$(vB_Fuel)'",
            "autoSort": true,
            "numFormatFromTemplate": true,
            "textAlign": {
              "auto": true,
              "align": "left"
            }
          },
          "qSortBy": {
            "qSortByState": 0,
            "qSortByFrequency": 0,
            "qSortByNumeric": -1,
            "qSortByAscii": 0,
            "qSortByLoadOrder": 1,
            "qSortByExpression": 0,
            "qExpression": {
              "qv": ""
            },
            "qSortByGreyness": 0
          },
          "qAttributeExpressions": [],
          "qAttributeDimensions": [],
          "qCalcCond": {
            "qv": ""
          },
          "qCalcCondition": {
            "qCond": {
              "qv": ""
            },
            "qMsg": {
              "qv": ""
            }
          }
        },
        maut:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "TripOmzet",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_TripOmzet(Kind={'Maut'}))",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_TripOmzet)' & ' > ' & '$(vB_Maut)'",
              "autoSort": true,
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        extra:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "TripOmzet",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_TripOmzet(Kind={'Extra Kosten'}))",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_TripOmzet)' & ' > ' & 'Extra Kosten'",
              "autoSort": true,
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        other:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "OrderOmzet",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_TripOmzet(Kind={'Other'}))",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_TripOmzet)' & ' > ' & '$(vB_Other)'",
              "autoSort": true,
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
    },
    kosten: {
        totaal: {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_TripKosten)",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_TripKosten)' & ' > ' & '$(vB_Alle)'",
              "autoSort": true,
              "cId": "eUNc",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        charter:   {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_TripKosten(Kind={'Charter'}))",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_TripKosten)' & ' > ' & '$(vB_Charter)'",
              "autoSort": true,
              "cId": "epyyN",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        rail:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_TripKosten(Kind={'RailFerry'}))",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_TripKosten)' & ' > ' & '$(vB_RailFerry)'",
              "autoSort": true,
              "cId": "uwdtmaD",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        toll:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_TripKosten(Kind={'Toll'}))",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_TripKosten)' & ' > ' & '$(vB_Toll)'",
              "autoSort": true,
              "cId": "fKyACW",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        cleaning:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_TripKosten(Kind={'Cleaning'}))",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_TripKosten)' & ' > ' & '$(vB_Cleaning)'",
              "autoSort": true,
              "cId": "sjpWGCN",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        codriver:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_TripKosten(Kind={'CoDriver'}))",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_TripKosten)' & ' > ' & '$(vB_CoDriver)'",
              "autoSort": true,
              "cId": "YjeF",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        collect:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_TripKosten(Kind={'Collect'}))",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_TripKosten)' & ' > ' & '$(vB_Collect)'",
              "autoSort": true,
              "cId": "VPUAJN",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        warehouse:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_TripKosten(Kind={'Warehouse'}))",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_TripKosten)' & ' > ' & '$(vB_Warehouse)'",
              "autoSort": true,
              "cId": "aJk",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        km:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_TripKosten(Kind={'KM'}))",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_TripKosten)' & ' > ' & 'KM'",
              "autoSort": true,
              "cId": "aJk1",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        other:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_TripKosten(Kind={'Other'}))",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_TripKosten)' & ' > ' & '$(vB_Other)'",
              "autoSort": true,
              "cId": "ucMQue",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        jib:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_TripKosten(Kind={'JIB'}))",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_TripKosten)' & ' > ' & 'JIB'",
              "autoSort": true,
              "cId": "ucMQue",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        boat:  {
              "qLibraryId": "",
              "qDef": {
                "qLabel": "",
                "qDescription": "",
                "qTags": [],
                "qGrouping": "N",
                "qDef": "$(vE_TripKosten(Kind={'Ferry'}))",
                "qNumFormat": {
                  "qType": "U",
                  "qnDec": 10,
                  "qUseThou": 0,
                  "qFmt": "",
                  "qDec": "",
                  "qThou": ""
                },
                "qRelative": false,
                "qBrutalSum": false,
                "qAggrFunc": "Expr",
                "qAccumulate": 0,
                "qReverseSort": false,
                "qActiveExpression": 0,
                "qExpressions": [],
                "qLabelExpression": "='$(vL_TripKosten)' & ' > ' & 'Boot'",
                "autoSort": true,
                "cId": "ucMQue",
                "numFormatFromTemplate": true,
                "textAlign": {
                  "auto": true,
                  "align": "left"
                }
              },
              "qSortBy": {
                "qSortByState": 0,
                "qSortByFrequency": 0,
                "qSortByNumeric": -1,
                "qSortByAscii": 0,
                "qSortByLoadOrder": 1,
                "qSortByExpression": 0,
                "qExpression": {
                  "qv": ""
                },
                "qSortByGreyness": 0
              },
              "qAttributeExpressions": [],
              "qAttributeDimensions": [],
              "qCalcCond": {
                "qv": ""
              },
              "qCalcCondition": {
                "qCond": {
                  "qv": ""
                },
                "qMsg": {
                  "qv": ""
                }
              }
            },
        fuelcorrection:{
                  "qLibraryId": "",
                  "qDef": {
                    "qLabel": "",
                    "qDescription": "",
                    "qTags": [],
                    "qGrouping": "N",
                    "qDef": "$(vE_TripKosten(Kind={'FuelCorrection'}))",
                    "qNumFormat": {
                      "qType": "U",
                      "qnDec": 10,
                      "qUseThou": 0,
                      "qFmt": "",
                      "qDec": "",
                      "qThou": ""
                    },
                    "qRelative": false,
                    "qBrutalSum": false,
                    "qAggrFunc": "Expr",
                    "qAccumulate": 0,
                    "qReverseSort": false,
                    "qActiveExpression": 0,
                    "qExpressions": [],
                    "qLabelExpression": "='$(vL_TripKosten)' & ' > ' & 'Correctie brandstof'",
                    "autoSort": true,
                    "cId": "ucMQue",
                    "numFormatFromTemplate": true,
                    "textAlign": {
                      "auto": true,
                      "align": "left"
                    }
                  },
                  "qSortBy": {
                    "qSortByState": 0,
                    "qSortByFrequency": 0,
                    "qSortByNumeric": -1,
                    "qSortByAscii": 0,
                    "qSortByLoadOrder": 1,
                    "qSortByExpression": 0,
                    "qExpression": {
                      "qv": ""
                    },
                    "qSortByGreyness": 0
                  },
                  "qAttributeExpressions": [],
                  "qAttributeDimensions": [],
                  "qCalcCond": {
                    "qv": ""
                  },
                  "qCalcCondition": {
                    "qCond": {
                      "qv": ""
                    },
                    "qMsg": {
                      "qv": ""
                    }
                  }
            },
    },
    aantal: {
        dossiers:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "OrderAantalDossiers",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_TripAantalDossiers)",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_TripAantalDossiers)'",
              "autoSort": true,
              "cId": "UUmPzu",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        orders:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "OrderAantalOrders",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_TripAantalOrders)",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_TripAantalOrders)'",
              "autoSort": true,
              "cId": "UUmPzu",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        ritten:   {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "TripAantalRitten",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_TripAantalRitten)",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_TripAantalRitten)'",
              "autoSort": true,
              "cId": "PjAQRY",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        afwijkingen:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "TripAantalAfwijkingen",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_TripAantalAfwijkingen)",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_TripAantalAfwijkingen)'",
              "autoSort": true,
              "cId": "PjAQRY",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          }
    },
    kpi: {
        goedkeur: {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "OrderPercGoedkeur",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_KPI_TripGoedkeur)",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_KPI_TripGoedkeur)'",
              "autoSort": true,
              "cId": "Rnnjp",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        normmarginpercent:   {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "OrderMarge",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_KPI_TripNormMarginPercent)",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_KPI_TripNormMarginPercent)'",
              "autoSort": true,
              "cId": "gkeLVN",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [
              {
                "qExpression": "if($(vE_KPI_OrderNormMarginPercent) < 0 ,rgb(255,0,0), rgb(0,127,0))",
                "qLibraryId": "",
                "qAttribute": true,
                "id": "cellForegroundColor"
              }
            ],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        normmarginomzet:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_KPI_TripNormMarginOverOmzet)",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_KPI_TripNormMarginOverOmzet)'",
              "autoSort": true,
              "cId": "axZsAXz",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [
              {
                "qExpression": "if($(vE_KPI_OrderNormMarginPercent) < 0 ,rgb(255,0,0), rgb(0,127,0))",
                "qLibraryId": "",
                "qAttribute": true,
                "id": "cellForegroundColor"
              }
            ],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          }
    },
    own: {
        omzetperuur: {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "OrderOmzetPerUur",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_KPI_TripOmzetPerUur)",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_KPI_TripOmzetPerUur)'",
              "autoSort": true,
              "cId": "MJZcvk",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [
              {
                "qExpression": "if($(vE_KPI_TripOmzetPerUur) < 0.85 * $(vE_TripNormTurnoverHour) ,rgb(176,23,31), if($(vE_KPI_TripOmzetPerUur) <  $(vE_TripNormTurnoverHour) ,rgb(244,164,96), rgb(46,139,87)))\r\n\n\n\n",
                "qLibraryId": "",
                "qAttribute": true,
                "id": "cellForegroundColor"
              }
            ],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        omzetperuurexfuel: {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "OrderOmzetPerUur",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_KPI_TripOmzetPerUurExFuel)",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_KPI_TripOmzetPerUurExFuel)'",
              "autoSort": true,
              "cId": "MJZcvk",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [
              {
                "qExpression": "if($(vE_KPI_TripOmzetPerUurExFuel) < 0.85 * $(vE_TripNormTurnoverHour) ,rgb(176,23,31), if($(vE_KPI_TripOmzetPerUurExFuel) <  $(vE_TripNormTurnoverHour) ,rgb(244,164,96), rgb(46,139,87)))\r\n\n\n\n",
                "qLibraryId": "",
                "qAttribute": true,
                "id": "cellForegroundColor"
              }
            ],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        omzetperuurrondrit: {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "OrderOmzetPerUur",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "$(vE_KPI_TripOmzetPerUurCombinatieVolgendeRit)",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_KPI_TripOmzetPerUurCombinatieVolgendeRit)'",
              "autoSort": true,
              "cId": "MJZcvk",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [
              {
                "qExpression": "if($(vE_KPI_TripOmzetPerUurCombinatieVolgendeRit) < 0.85 * $(vE_TripNormTurnoverHour) ,rgb(176,23,31), if($(vE_KPI_TripOmzetPerUurCombinatieVolgendeRit) <  $(vE_TripNormTurnoverHour) ,rgb(244,164,96), rgb(46,139,87)))\r\n\n\n\n",
                "qLibraryId": "",
                "qAttribute": true,
                "id": "cellForegroundColor"
              }
            ],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        normomzetperuur:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "=$(vE_TripNormTurnoverHour)",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_TripNormTurnoverHour)'",
              "autoSort": true,
              "cId": "qcGe",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          },
        bezetting:  {
            "qLibraryId": "",
            "qDef": {
              "qLabel": "",
              "qDescription": "",
              "qTags": [],
              "qGrouping": "N",
              "qDef": "=$(vE_KPI_TripBezetting)",
              "qNumFormat": {
                "qType": "U",
                "qnDec": 10,
                "qUseThou": 0,
                "qFmt": "",
                "qDec": "",
                "qThou": ""
              },
              "qRelative": false,
              "qBrutalSum": false,
              "qAggrFunc": "Expr",
              "qAccumulate": 0,
              "qReverseSort": false,
              "qActiveExpression": 0,
              "qExpressions": [],
              "qLabelExpression": "='$(vL_KPI_TripBezetting)'",
              "autoSort": true,
              "cId": "TCNkRn",
              "numFormatFromTemplate": true,
              "textAlign": {
                "auto": true,
                "align": "left"
              }
            },
            "qSortBy": {
              "qSortByState": 0,
              "qSortByFrequency": 0,
              "qSortByNumeric": -1,
              "qSortByAscii": 0,
              "qSortByLoadOrder": 1,
              "qSortByExpression": 0,
              "qExpression": {
                "qv": ""
              },
              "qSortByGreyness": 0
            },
            "qAttributeExpressions": [
              {
                "qExpression": "if($(vE_KPI_OrderBezetting) < 0 ,rgb(255,0,0), RGB(34,177,76))",
                "qLibraryId": "",
                "qAttribute": true,
                "id": "cellForegroundColor"
              }
            ],
            "qAttributeDimensions": [],
            "qCalcCond": {
              "qv": ""
            },
            "qCalcCondition": {
              "qCond": {
                "qv": ""
              },
              "qMsg": {
                "qv": ""
              }
            }
          }
    },
    outsourced: {
      percentage: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_KPI_TripTripOutsourced)\t",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_KPI_TripTripOutsourced)'",
          "autoSort": true,
          "cId": "fPNrVLL",
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      marge: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_KPI_TripCharterMarge)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_KPI_TripCharterMarge)'",
          "autoSort": true,
          "cId": "jfPSwtw",
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [
          {
            "qExpression": "if($(vE_KPI_OrderCharterMarge) < 0, rgb(176,23,31), if($(vE_KPI_OrderCharterMarge) < $(vE_OrderNormCharter) ,rgb(244,164,96), rgb(46,139,87)))",
            "qLibraryId": "",
            "qAttribute": true,
            "id": "cellForegroundColor"
          }
        ],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      norm: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_TripNormCharter)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_TripNormCharter)",
          "autoSort": true,
          "cId": "VehHD",
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      }
    },
    km: {
      omzetperkm: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "=$(vE_KPI_TripOmzetPerKM)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_KPI_TripOmzetPerKM)'",
          "autoSort": true,
          "cId": "PZLtX",
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [
          {
            "qExpression": "if($(vE_KPI_OrderOmzetPerKM) < $(vE_OrderNormTurnoverKM) ,rgb(255,0,0), rgb(0,127,0))",
            "qLibraryId": "",
            "qAttribute": true,
            "id": "cellForegroundColor"
          }
        ],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      omzetperkmfull: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_KPI_TripOmzetPerKMFull)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_KPI_TripOmzetPerKMFull))'",
          "autoSort": true,
          "cId": "RtDbpmg",
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [
          {
            "qExpression": "if($(vE_KPI_OrderOmzetPerKMFull) < $(vE_OrderNormTurnoverKM) ,rgb(255,0,0), rgb(0,127,0))",
            "qLibraryId": "",
            "qAttribute": true,
            "id": "cellForegroundColor"
          }
        ],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      belading: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "=$(vE_KPI_TripBelading)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_KPI_TripBelading)'",
          "autoSort": true,
          "cId": "XsjRvj",
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      km: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_TripKM)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='KM >' &  '$(vB_Alle)'",
          "autoSort": true,
          "cId": "eyFxN",
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      kmfull: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_TripKM(Kind={'Full'} ))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='KM >' &  '$(vB_Full)'",
          "autoSort": true,
          "cId": "DDXhmkT",
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      kmempty: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_TripKM(Kind={'Empty'} ))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='KM >' &  '$(vB_Empty)'",
          "autoSort": true,
          "cId": "jcpszEx",
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      normomzetperkm: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_TripNormTurnoverKM)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_TripNormTurnoverKM)'",
          "autoSort": true,
          "cId": "WjmPM",
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      normomzetperkmfull: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_OrderNormTurnoverKMFull)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_OrderNormTurnoverKMFull)'",
          "autoSort": true,
          "cId": "vQmHLwc",
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      }
    },
    uren: {
      totaal: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_TripUren)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "= '$(vL_TripUren)'  & ' > ' & '$(vB_Alle)' & ' (' & '$(vB_Sum)' & ')'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      loading: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_TripUren(Kind={'Loading'}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "= '$(vL_TripUren)'  & ' > ' & '$(vB_Loading)' & ' (' & '$(vB_Sum)' & ')'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      unloading: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_TripUren(Kind={'Unloading'}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "= '$(vL_TripUren)'  & ' > ' & '$(vB_Unloading)' & ' (' & '$(vB_Sum)' & ')'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      driving: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_TripUren(Kind={'Driving'}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "= '$(vL_TripUren)'  & ' > ' & '$(vB_Driving)' & ' (' & '$(vB_Sum)' & ')'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      cleaning: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_TripUren(Kind={'Cleaning'}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "= '$(vL_TripUren)'  & ' > ' & '$(vB_Cleaning)' & ' (' & '$(vB_Sum)' & ')'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      other: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_TripUren(Kind={'Other'}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "= '$(vL_TripUren)'  & ' > ' & '$(vB_Other)' & ' (' & '$(vB_Sum)' & ')'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      avg_totaal: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_TripUrenAvg)",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "= '$(vL_TripUrenAvg)'  & ' > ' & '$(vB_Alle)' & ' (' & '$(vB_Avg)' & ')'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      avg_loading: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_TripUrenAvg(Kind={'Loading'}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "= '$(vL_TripUrenAvg)'  & ' > ' & '$(vB_Loading)' & ' (' & '$(vB_Avg)' & ')'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      avg_unloading: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_TripUrenAvg(Kind={'Unloading'}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "= '$(vL_TripUrenAvg)'  & ' > ' & '$(vB_Unloading)' & ' (' & '$(vB_Avg)' & ')'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      avg_driving: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_TripUrenAvg(Kind={'Driving'}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "= '$(vL_TripUrenAvg)'  & ' > ' & '$(vB_Driving)' & ' (' & '$(vB_Avg)' & ')'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      avg_cleaning: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_TripUrenAvg(Kind={'Cleaning'}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "= '$(vL_TripUrenAvg)'  & ' > ' & '$(vB_Cleaning)' & ' (' & '$(vB_Avg)' & ')'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      avg_other: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_TripUrenAvg(Kind={'Other'}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "= '$(vL_TripUrenAvg)'  & ' > ' & '$(vB_Other)' & ' (' & '$(vB_Avg)' & ')'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      }
    },
    previousyear: {
      omzet: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_TripOmzet($(vD_Year)={\"$(=Only($(vD_Year))-1)\"}))\r\n\n",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_TripOmzet) ' & (only($(vD_Year)) - 1)",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      orders: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_TripAantalOrders($(vD_Year)={\"$(=Only($(vD_Year))-1)\"}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_TripAantalOrders) ' & (only($(vD_Year)) - 1)",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      ritten: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_TripAantalRitten($(vD_Year)={\"$(=Only($(vD_Year))-1)\"}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_TripAantalRitten) ' & (only($(vD_Year)) - 1)",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      normmarge: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_KPI_TripNormMarginPercent($(vD_Year)={\"$(=Only($(vD_Year))-1)\"}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_KPI_TripNormMarginPercent) ' & (only($(vD_Year)) - 1)",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      normmargeomzet: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_KPI_TripNormMarginOverOmzet($(vD_Year)={\"$(=Only($(vD_Year))-1)\"}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_KPI_TripNormMarginOverOmzet) ' & (only($(vD_Year)) - 1)",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      goedkeur: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_KPI_TripGoedkeur($(vD_Year)={\"$(=Only($(vD_Year))-1)\"}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_KPI_TripGoedkeur) ' & (only($(vD_Year)) - 1)",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      omzetperuur: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_KPI_TripOmzetPerUur($(vD_Year)={\"$(=Only($(vD_Year))-1)\"}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_KPI_TripOmzetPerUur) ' & (only($(vD_Year)) - 1)",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      uitbesteed: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_KPI_TripTripOutsourced($(vD_Year)={\"$(=Only($(vD_Year))-1)\"}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_KPI_TripTripOutsourced) ' & (only($(vD_Year)) - 1)",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      volgendeorder: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_KPI_TripOmzetPerUurCombinatieVolgendeOrder($(vD_Year)={\"$(=Only($(vD_Year))-1)\"}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_KPI_TripOmzetPerUurCombinatieVolgendeOrder) ' & (only($(vD_Year)) - 1)",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      chargermarge: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_KPI_TripCharterMarge($(vD_Year)={\"$(=Only($(vD_Year))-1)\"}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_KPI_TripCharterMarge) ' & (only($(vD_Year)) - 1)",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      lm: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_TripLM($(vD_Year)={\"$(=Only($(vD_Year))-1)\"}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_TripLM)  ' & (only($(vD_Year)) - 1)'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      avg_lm: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_TripLMAvg($(vD_Year)={\"$(=Only($(vD_Year))-1)\"}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_TripLMAvg) ' & (only($(vD_Year)) - 1)'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      kg: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_TripKG($(vD_Year)={\"$(=Only($(vD_Year))-1)\"}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_TripKG) ' & (only($(vD_Year)) - 1)'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
      avg_kg: {
        "qLibraryId": "",
        "qDef": {
          "qLabel": "",
          "qDescription": "",
          "qTags": [],
          "qGrouping": "N",
          "qDef": "$(vE_TripKGAvg($(vD_Year)={\"$(=Only($(vD_Year))-1)\"}))",
          "qNumFormat": {
            "qType": "U",
            "qnDec": 10,
            "qUseThou": 0,
            "qFmt": "",
            "qDec": "",
            "qThou": ""
          },
          "qRelative": false,
          "qBrutalSum": false,
          "qAggrFunc": "Expr",
          "qAccumulate": 0,
          "qReverseSort": false,
          "qActiveExpression": 0,
          "qExpressions": [],
          "qLabelExpression": "='$(vL_TripKGAvg) ' & (only($(vD_Year)) - 1)'",
          "autoSort": true,
          "numFormatFromTemplate": true,
          "textAlign": {
            "auto": true,
            "align": "left"
          }
        },
        "qSortBy": {
          "qSortByState": 0,
          "qSortByFrequency": 0,
          "qSortByNumeric": -1,
          "qSortByAscii": 0,
          "qSortByLoadOrder": 1,
          "qSortByExpression": 0,
          "qExpression": {
            "qv": ""
          },
          "qSortByGreyness": 0
        },
        "qAttributeExpressions": [],
        "qAttributeDimensions": [],
        "qCalcCond": {
          "qv": ""
        },
        "qCalcCondition": {
          "qCond": {
            "qv": ""
          },
          "qMsg": {
            "qv": ""
          }
        }
      },
    }
};

export {measures, dimensions};